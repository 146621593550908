import React from "react"
import styled from "@emotion/styled";
import Layout from '../components/layout';
import Card from '../components/card';
import ProductListItem from '../components/product-list-item';
import useProducts from '../hooks/use-products';

const CardProducts = styled(Card)`
  display: flex;
  flex-wrap: wrap;
`;

export default () => {
  const products = useProducts();

  return (
    <Layout>
      <section>
        <h2>Repair</h2>
        <CardProducts>
          {products.repair.map(post => (
            <ProductListItem key={post.slug} product={post}></ProductListItem>
          ))}
        </CardProducts>

        <h2>Decontamination</h2>
        <CardProducts>
          {products.decontamination.map(post => (
            <ProductListItem key={post.slug} product={post}></ProductListItem>
          ))}
        </CardProducts>

        <h2>Compounds &amp; Polishes</h2>
        <CardProducts>
          {products['compounds-polishes'].map(post => (
            <ProductListItem key={post.slug} product={post}></ProductListItem>
          ))}
        </CardProducts>

        <h2>Headlight Restoration &amp; Protection</h2>
        <CardProducts>
          {products['headlight'].map(post => (
            <ProductListItem key={post.slug} product={post}></ProductListItem>
          ))}
        </CardProducts>

        <h2>Waxes &amp; Sealants</h2>
        <CardProducts>
          {products['waxes-sealants'].map(post => (
            <ProductListItem key={post.slug} product={post}></ProductListItem>
          ))}
        </CardProducts>

        <h2>Exterior Plastic &amp; Rubber Protection</h2>
        <CardProducts>
          {products['exterior-trim-rubber'].map(post => (
            <ProductListItem key={post.slug} product={post}></ProductListItem>
          ))}
        </CardProducts>

        <h2>Interior Leather Care</h2>
        <CardProducts>
          {products['leather-fabric-care'].map(post => (
            <ProductListItem key={post.slug} product={post}></ProductListItem>
          ))}
        </CardProducts>
      </section>
    </Layout>
  );
};
