import { graphql, useStaticQuery } from 'gatsby';
import mapProduct from '../common/map-product';

const useProducts = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allMdx {
        nodes {
          frontmatter {
            slug
            title
            categories
            price
            images
          }
        }
      }
    }
  `);

  return data.allMdx.nodes
    .map(mapProduct)
    .reduce((productsByCategories, product) => {
      product.categories.forEach(category => {
        if (!productsByCategories[category]) {
          productsByCategories[category] = [];
        }
        productsByCategories[category].push(product);
        productsByCategories[category].sort((a, b) => {
          if (a.title < b.title) return -1;
          else if (b.title < a.title) return 1;
          else return 0;
        });
      });
      return productsByCategories;
    }, {})
  ;
};

export default useProducts;
